// @ts-nocheck
import Img from 'gatsby-image';
import React, { useEffect, useRef, useState } from 'react';
import { colors, desktopBreakpoint, desktopVW, mobileVW } from '../../styles';
import styled from 'styled-components';

import 'flickity/css/flickity.css';
import { useGlobalDictionaryQuery } from '../../hooks';
import LinkTool from '../shared/LinkTool';
import Button from '../shared/Button';

type Props = {
  backgroundColorButtonInHeroSection: string;
  backgroundColorButtonInHeroSectionHover: string;
  textColorButtonInHeroSectionHover: string;
  textColorButtonInHeroSection: string;
  borderColorButtonInHeroSection: string;
  borderColorButtonInHeroSectionHover: string;
  backgroundHero: string;
};

export default function HomeHeroSlideShow(props: Props) {
  const {
    backgroundColorButtonInHeroSection,
    backgroundColorButtonInHeroSectionHover,
    textColorButtonInHeroSectionHover,
    textColorButtonInHeroSection,
    borderColorButtonInHeroSection,
    borderColorButtonInHeroSectionHover,
    backgroundHero,
  } = props;

  const dictionary = useGlobalDictionaryQuery();
  const carouselRef = useRef(null);
  const flktyRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  let style = {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const updateIndex = index => {
    flktyRef.current.select(index);
    setCurrentIndex(index);
  };

  useEffect(() => {
    if (typeof window != 'undefined') {
      const Flickity = require('flickity');
      flktyRef.current = new Flickity(carouselRef.current, {
        cellAlign: 'left',
        contain: true,
        freeScroll: false,
        wrapAround: true,
        pageDots: false,
        prevNextButtons: false,
        autoPlay: 3500,
      });

      flktyRef.current.on('select', () => {
        setCurrentIndex(flktyRef.current.selectedIndex);
      });

      return () => {
        flktyRef.current.destroy();
      };
    }
  }, []);

  const totalCells = flktyRef.current ? flktyRef.current.cells.length : 0;

  return (
    <Wrapper>
      <ContentInner>
        <Heading>{dictionary?.homeHeroSlideshow?.[currentIndex ?? 0]?.title}</Heading>
        <Subtitle>{dictionary?.homeHeroSlideshow?.[currentIndex ?? 0]?.subtitle.subtitle}</Subtitle>
        <CustomDots>
          {Array.from({ length: totalCells }).map((_, index) => (
            <a
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => updateIndex(index)}
            >
              {index + 1}
            </a>
          ))}
        </CustomDots>
      </ContentInner>
      <RightContent>
        <LinkTool to={`/${dictionary?.homeHeroSlideshow?.[currentIndex]?.collection?.slug}`}>
          <Button
            text={dictionary?.explore}
            Type="button"
            AriaLabel="Send"
            width={mobileVW(350)}
            widthDesk={desktopVW(238)}
            color={textColorButtonInHeroSection}
            colorHover={textColorButtonInHeroSectionHover}
            colorBackground={backgroundColorButtonInHeroSection}
            colorBackgroundHover={backgroundColorButtonInHeroSectionHover}
            colorBorder={borderColorButtonInHeroSection}
            colorBorderHover={borderColorButtonInHeroSectionHover}
          />
        </LinkTool>
      </RightContent>
      <div className="carousel" ref={carouselRef} style={{ height: '100%' }}>
        {dictionary.homeHeroSlideshow?.map((item: any, i: number) => (
          <div className="carousel-cell" key={i} style={{ ...style }}>
            <StyledImage fluid={item?.image?.fluid} />
          </div>
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 95vh;
  font-family: 'messinaMono';
  color: ${colors.white};

  ${desktopBreakpoint} {
    height: 100vh;
  }
`;
const StyledImage = styled(Img)`
  width: 100%;
  height: 100%;
`;

const ContentInner = styled.div`
  position: absolute;
  z-index: 2;
  left: ${mobileVW(15)};
  bottom: ${mobileVW(150)};
  ${desktopBreakpoint} {
    left: ${desktopVW(40)};
    bottom: ${desktopVW(100)};
    max-width: 50%;
  }
`;
const RightContent = styled.div`
  position: absolute;
  z-index: 2;
  right: ${mobileVW(30)};
  bottom: ${mobileVW(40)};
  ${desktopBreakpoint} {
    right: ${desktopVW(60)};
    bottom: ${desktopVW(70)};
    max-width: 50%;
  }
`;
const Heading = styled.div`
  font-weight: bold;
  font-size: ${mobileVW(38)};
  margin-bottom: ${mobileVW(10)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(41)};
    margin-bottom: ${desktopVW(10)};
  }
`;
const Subtitle = styled.div`
  font-size: ${mobileVW(18)};
  margin-bottom: ${mobileVW(20)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(20)};
    margin-bottom: ${desktopVW(40)};
  }
`;

const CustomDots = styled.div`
  a {
    cursor: pointer;
    font-weight: 600;
  }
  font-size: ${mobileVW(12)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(12.6)};
  }
  display: flex;

  a {
    width: ${mobileVW(30)};
    margin-right: ${mobileVW(5)};
    display: flex;
    justify-content: center;
    line-height: ${mobileVW(19)};
    ${desktopBreakpoint} {
      width: ${desktopVW(30)};
      margin-right: ${desktopVW(5)};
      line-height: ${desktopVW(19)};
    }
  }
  a.active {
    border-bottom: ${mobileVW(2.5)} solid #fff;
    ${desktopBreakpoint} {
      border-bottom: ${desktopVW(2.5)} solid #fff;
    }
  }
`;
